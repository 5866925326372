module.exports = {
  urls: {
    api: "http://dev-api.angelussalesportal.com",
    www: "http://dev.angelussalesportal.com"
  },
  auth0: {
    domain: "angelusblock.auth0.com",
    clientId: "cgHJNZP3hODmsE2ExepTUT9bMn63BW2w",
    callbackUrl: "http://dev.angelussalesportal.com/callback"
  },
  segment: {
    key: "RJfL6atOUM1CFtpnEvNOWDa8BAlcjiVF"
  }
};
