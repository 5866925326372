module.exports = {
  urls: {
    api: "http://localhost:5000",
    www: "http://localhost:3000"
  },
  auth0: {
    domain: "angelusblock.auth0.com",
    clientId: "cgHJNZP3hODmsE2ExepTUT9bMn63BW2w",
    callbackUrl: "http://localhost:3000/callback"
  },
  segment: {
    key: "RJfL6atOUM1CFtpnEvNOWDa8BAlcjiVF"
  }
};
