import React from "react";
import ReactDOM from "react-dom";
// import UIKit from 'uikit'

// Components
import App from "./App";

// Global Styles
import "../node_modules/uikit/dist/css/uikit.css";
import "./static/css/theme.css";

ReactDOM.render(<App />, document.getElementById("root"));
