import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

// Components
import Logo from "./Logo";

// Helpers
import Auth from "../../helpers/Auth";

class Nav extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};

    this.auth = new Auth();

    this.onClickLogin = this.onClickLogin.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
  }

  onClickLogin() {
    this.auth.login();
  }

  onClickLogout() {
    this.auth.logout(this.props.history);
  }

  render() {
    if (!this.props.isAuthenticated) {
      return (
        <nav
          className="uk-navbar uk-navbar-container uk-margin"
          data-uk-navbar=""
        >
          <div className="uk-navbar-left">
            <Logo />
          </div>

          <div className="uk-navbar-right">
            <div className="uk-navbar-item">
              <button
                className="uk-button uk-button-primary"
                onClick={this.onClickLogin}
              >
                Login
              </button>
            </div>
          </div>
        </nav>
      );
    }

    const pathname = this.props.location.pathname;

    return (
      <nav
        className="uk-navbar uk-navbar-container uk-margin"
        data-uk-navbar=""
      >
        {this.props.profile.srep_code && (
          <div className="uk-navbar-left">
            <Logo />
            <ul className="uk-navbar-nav">
              <li className={pathname.startsWith("/items") ? "uk-active" : ""}>
                <Link to="/items">Items</Link>
              </li>
              <li
                className={pathname.startsWith("/customers") ? "uk-active" : ""}
              >
                <Link to="/customers">Customers</Link>
              </li>
              <li className={pathname.startsWith("/orders") ? "uk-active" : ""}>
                <Link to="/orders">ABC POs</Link>
              </li>
            </ul>
          </div>
        )}

        {this.props.profile.cust_code && (
          <div className="uk-navbar-left">
            <Logo />
            <ul className="uk-navbar-nav">
              <li className={pathname.startsWith("/orders") ? "uk-active" : ""}>
                <Link to="/items">Orders</Link>
              </li>
            </ul>
          </div>
        )}

        <div className="uk-navbar-right">
          <div className="uk-navbar-item">
            <button
              className="uk-button uk-button-primary"
              onClick={this.onClickLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(Nav);
