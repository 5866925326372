import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  loadingText: PropTypes.string,
  loadingTextLarge: PropTypes.bool
};
const defaultProps = {
  loadingText: "Loading...",
  loadingTextLarge: false
};

const Loader = ({ loadingText, loadingTextLarge }) => (
  <div className="uk-section uk-text-center">
    <div data-uk-spinner="" />
    {loadingTextLarge ? (
      <div className="uk-text-lead uk-margin-small-top">{loadingText}</div>
    ) : (
      <div className="uk-text-muted uk-margin-small-top">{loadingText}</div>
    )}
  </div>
);

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
