import React from "react";
import axios from "axios";
import AsyncSelect from "react-select/lib/Async";
import settings from "../settings";

// Components
import ItemDetailTable from "../components/ItemDetailTable";
import Loader from "../components/Loader";
import Nav from "../components/Navi";

class ItemsDetail extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      itemDetails: null,
      warehouseItems: [],
      loading: true
    };

    this.getItemDetails = this.getItemDetails.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.findItem = this.findItem.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.setState({ customer: null, loading: true }, () => {
        this.getItemDetails(nextProps.match.params.id);
      });
    }
  }

  componentDidMount() {
    this.getItemDetails(this.props.match.params.id);
  }

  getItemDetails(itemId) {
    this.setState({ loading: true });
    axios
      .get(`${settings.urls.api}/item/${itemId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        const warehouseItems = result.data.warehouses;
        delete result.data.warehouses;
        this.setState({
          itemDetails: result.data,
          warehouseItems
        });
      })
      .catch(e => {
        console.error("error: ", e);
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  loadOptions(inputValue) {
    return new Promise(resolve => {
      this.findItem(inputValue).then(result => {
        resolve(result);
      });
    });
  }

  findItem(inputValue) {
    return axios
      .get(`${settings.urls.api}/item/search?query=${inputValue}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        const items = [];
        result.data.map(item => {
          let list_price = item.list_price;
          if (item.is_paver) {
            list_price = item.paver_list_price;
          }
          const delivered_price =
            parseFloat(item.delivery_markup) + parseFloat(list_price);

          return items.push({
            value: item.code,
            label: `${item.description} (${
              item.total_qty
            }) $${delivered_price.toFixed(2)}`
          });
        });
        return items;
      });
  }

  onUpdate(selectedItem) {
    this.props.history.push(`/items/${selectedItem.value}`);
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  render() {
    return (
      <>
        <Nav
          isAuthenticated={this.props.isAuthenticated}
          profile={this.props.profile}
        />
        <main className="uk-container">
          <div className="uk-section uk-section-muted">
            <div className="uk-container uk-container-xsmall">
              <h3>Item Search</h3>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={this.loadOptions}
                onChange={this.onUpdate}
                placeholder="Search for an item..."
              />
            </div>
          </div>
          {this.state.loading ? (
            <Loader />
          ) : (
            <div className="uk-section uk-section-small">
              <div className="uk-container">
                <ItemDetailTable
                  item={this.state.itemDetails}
                  warehouseItems={this.state.warehouseItems}
                />
              </div>
            </div>
          )}
        </main>
      </>
    );
  }
}

export default ItemsDetail;
