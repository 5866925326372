module.exports = {
  urls: {
    api: "https://api.angelussalesportal.com",
    www: "https://www.angelusorders.com"
  },
  auth0: {
    domain: "angelusblock.auth0.com",
    clientId: "cgHJNZP3hODmsE2ExepTUT9bMn63BW2w",
    callbackUrl: "https://www.angelusorders.com/callback"
  },
  segment: {
    key: "lQmz8HzwZGfTrASyI4CjyrHErIdWEpN4"
  }
};
