import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AsyncSelect from "react-select/lib/Async";
import settings from "../settings";

// Components
import Nav from "../components/Navi";

class Customers extends React.Component {
  loadOptions = inputValue => {
    return new Promise(resolve => {
      this.findItem(inputValue).then(result => {
        resolve(result);
      });
    });
  };

  findItem = inputValue => {
    return axios
      .get(
        `${settings.urls.api}/customer/search?name=${inputValue.toUpperCase()}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("id_token")}`
          }
        }
      )
      .then(result => {
        const items = [];
        result.data.map(customer => {
          return items.push({
            value: customer.code,
            label: `${customer.name} (${customer.code})`
          });
        });
        return items;
      });
  };

  onUpdate = selectedItem => {
    this.props.history.push(`/customers/${selectedItem.value}`);
  };

  render() {
    return (
      <>
        <Nav
          isAuthenticated={this.props.isAuthenticated}
          profile={this.props.profile}
        />
        <main className="uk-container">
          <div className="uk-section uk-section-muted">
            <div className="uk-container uk-container-xsmall">
              <h3>Customer Search</h3>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={this.loadOptions}
                onChange={this.onUpdate}
                placeholder="Search for a customer..."
              />
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withRouter(Customers);
