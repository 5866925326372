import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AsyncSelect from "react-select/lib/Async";
import settings from "../settings";

// Components
import Loader from "../components/Loader";
import Nav from "../components/Navi";

class Customers extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      address_name: "",
      address_1: "",
      address_2: "",
      address_city: "",
      address_state: "CA",
      address_zip: "",
      customerPONumber: "",
      po_required: "",
      instructions: "",
      jobNumber: "",
      job_required: "",
      location: null,
      pickupOrDelivered: "pickup",
      items: [],
      loadingItemHistory: true,
      discount_rate: null,
      showPricing: true,
      totalPrice: 0,
      hasSearchedForItem: false,
      isSubmittingOrder: false,
      displayOrderThankYou: false,
      displayOrderError: false,
      formLocationError: false,
      formCustomerPOError: false,
      formJobNumberError: false,
      formItemsError: false
    };

    this.getLocations = this.getLocations.bind(this);

    // address handlers
    this.handleChangeAddressName = this.handleChangeAddressName.bind(this);
    this.handleChangeAddress1 = this.handleChangeAddress1.bind(this);
    this.handleChangeAddress2 = this.handleChangeAddress2.bind(this);
    this.handleChangeAddressCity = this.handleChangeAddressCity.bind(this);
    this.handleChangeAddressState = this.handleChangeAddressState.bind(this);
    this.handleChangeAddressZip = this.handleChangeAddressZip.bind(this);

    this.handleChangeCustomerPONumber = this.handleChangeCustomerPONumber.bind(
      this
    );
    this.handleChangeInstructions = this.handleChangeInstructions.bind(this);
    this.handleChangeJobNumber = this.handleChangeJobNumber.bind(this);
    this.handleChangePickupOrDelivered = this.handleChangePickupOrDelivered.bind(
      this
    );
    this.handleUpdateLocation = this.handleUpdateLocation.bind(this);

    this.handleChangePalletQuantity = this.handleChangePalletQuantity.bind(
      this
    );
    this.handleChangePieceQuantity = this.handleChangePieceQuantity.bind(this);
    this.handleExitPalletQuantity = this.handleExitPalletQuantity.bind(this);
    this.handleExitPieceQuantity = this.handleExitPieceQuantity.bind(this);

    this.loadOptions = this.loadOptions.bind(this);
    this.findItem = this.findItem.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    this.submitOrder = this.submitOrder.bind(this);
  }

  componentDidMount() {
    this.getCustomerHistory();
  }

  getCustomerHistory() {
    // getCustomerHistory(loc) {
    this.setState({ loadingItemHistory: true });
    // console.log("here: ", loc);
    // console.log("api: ", `${settings.urls.api}`);
    // var location_url = `${settings.urls.api}/customer/me`;
    // console.log("loc url: ", location_url);
    // if (loc !== undefined) {
    //   console.log("location defined: ", loc);
    //   location_url = location_url + "?location=" + loc;
    // }
    // console.log("location_url: ", location_url);
    return axios
      .get(`${settings.urls.api}/customer/me`, {
        // .get(location_url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        let history = [];

        result.data.items.map(item =>
          history.push({
            ...item,
            pallet_quantity: "",
            piece_quantity: "",
            isFromHistory: true
          })
        );

        if (
          // result.data.show_pricing === true
          history[0].show_pricing === true ||
          history[0].show_pricing === "Y"
        ) {
          this.setState({ showPricing: true });
        } else {
          this.setState({ showPricing: false });
        }

        this.setState({
          items: history,
          discount_rate: result.data.discount_rate,
          show_pricing: result.data.show_pricing,
          po_required: result.data.po_required,
          job_required: result.data.job_required
        });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => this.setState({ loadingItemHistory: false }));
  }

  getLocations() {
    return axios
      .get(`${settings.urls.api}/location`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        const locations = [];
        result.data.results
          .sort((a, b) => (a.description > b.description ? 1 : -1))
          .map(location => {
            return locations.push({
              value: location.code,
              label: `${location.description}`
            });
          });
        return locations;
      });
  }

  handleChangeAddressName(e) {
    if (e.target.value.length <= 35) {
      this.setState({ address_name: e.target.value });
    }
  }

  handleChangeAddress1(e) {
    if (e.target.value.length <= 35) {
      this.setState({ address_1: e.target.value });
    }
  }

  handleChangeAddress2(e) {
    if (e.target.value.length <= 35) {
      this.setState({ address_2: e.target.value });
    }
  }

  handleChangeAddressCity(e) {
    if (e.target.value.length <= 25) {
      this.setState({ address_city: e.target.value });
    }
  }

  handleChangeAddressState(e) {
    this.setState({ address_state: e.target.value });
  }

  handleChangeAddressZip(e) {
    this.setState({ address_zip: e.target.value });
  }

  handleChangeCustomerPONumber(e) {
    if (e.target.value.length <= 15) {
      this.setState({ customerPONumber: e.target.value });
    }
  }

  handleChangeInstructions(e) {
    if (e.target.value.length <= 255) {
      this.setState({ instructions: e.target.value });
    }
  }

  handleChangeJobNumber(e) {
    if (e.target.value.length <= 12) {
      this.setState({ jobNumber: e.target.value });
    }
  }

  handleChangePickupOrDelivered(e) {
    this.setState({ pickupOrDelivered: e.target.value });
  }

  handleUpdateLocation(selectedItem) {
    this.setState({
      formLocationError: false,
      location: selectedItem.value
    });

    //    if (this.totalPrice === 0) {  for future when history is by location
    //        this.getCustomerHistory()
    //    }
    // Get new pricing by location
    // console.log(selectedItem.value);

    // this.getCustomerHistory(selectedItem.value);

    // for future when history is by location
  }

  loadOptions(inputValue) {
    return new Promise(resolve => {
      this.findItem(inputValue).then(result => {
        resolve(result);
      });
    });
  }

  findItem(inputValue) {
    return axios
      .get(`${settings.urls.api}/item/search?query=${inputValue}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        const items = [];
        result.data.map(item => {
          let netPrice = item.list_price;
          // console.log("netx: ", netPrice, item.premium_markup);

          if (netPrice === null || netPrice === undefined) {
            netPrice = 0;
          }
          netPrice = netPrice.toFixed(3);

          if (item.show_pricing === "Y") {
            return items.push({
              value: item,
              //            label: `${item.description} (${item.total_qty}) $${netPrice}`
              label: `${item.description} $${netPrice}`
            });
          } else {
            return items.push({
              value: item,
              label: `${item.description}`
            });
          }
        });
        return items;
      });
  }

  onUpdate(selectedItem) {
    //    this.setState({ hasSearchedForItem: true })

    let item = {
      ...selectedItem.value,
      isFromHistory: false,
      pallet_quantity: "",
      piece_quantity: ""
    };
    let items = this.state.items;
    var index_found = items.findIndex(x => x.code === item.code);
    if (index_found === -1) {
      items.unshift(item);
      this.setState({ items });
    }
  }

  handleChangePalletQuantity(event, key) {
    let { items } = this.state;
    let quantity = parseInt(event.target.value);

    if (quantity > 999) {
      quantity = 999;
    }

    if (Number.isInteger(quantity)) {
      items[key].pallet_quantity = quantity;
      this.setState({
        formItemsError: false,
        items
      });
    } else {
      items[key].pallet_quantity = "";
      this.setState({
        formItemsError: false,
        items
      });
    }
  }

  handleChangePieceQuantity(event, key) {
    let { items } = this.state;
    let quantity = parseInt(event.target.value);
    // console.log("qty: ", quantity);
    if (quantity > 99999) {
      quantity = 99999;
    }

    if (Number.isInteger(quantity)) {
      items[key].piece_quantity = quantity;

      this.setState({
        formItemsError: false,
        items
      });
    } else {
      items[key].piece_quantity = "";
      this.setState({
        formItemsError: false,
        items
      });
    }
  }

  handleExitPalletQuantity(event, key) {
    let { items } = this.state;

    if (
      items[key].blocks_per_pallet === 0 ||
      items[key].pallet_quantity === 0
    ) {
      items[key].pallet_quantity = "";
    }
    this.setState({
      formItemsError: false,
      items
    });
  }

  handleExitPieceQuantity(event, key) {
    let { items } = this.state;

    let quantity = 0;
    if (event.target.value !== "") {
      quantity = parseInt(event.target.value);
    }
    // Don't round up the following conditons:

    if (
      items[key].is_paver &&
      quantity !== 0 &&
      items[key].company_code === "ABC" &&
      items[key].blocks_per_pallet > 1 &&
      items[key].code.substring(0, 4) !== "W84D" &&
      items[key].code.substring(0, 4) !== "WT12" &&
      items[key].code.substring(0, 8) !== "XX60-BNC" &&
      items[key].code.substring(0, 8) !== "XX60-BN6" &&
      items[key].product_code !== "ACCESS" &&
      items[key].product_code !== "SACK"
    ) {
      // Round up to a FULL pallets
      if (
        items[key].code.substring(0, 8) === "X100-AQU" ||
        items[key].code.substring(0, 8) === "XX70-TBR"
      ) {
        // Round up to FULL pallet
        items[key].pallet_quantity = parseInt(
          (quantity + items[key].blocks_per_pallet - 1) /
            items[key].blocks_per_pallet
        );
        items[key].piece_quantity = 0;
      } else {
        // Round up to HALF pallet
        const full_pallet_qty = items[key].blocks_per_pallet;
        const half_pallet_qty = parseInt(
          (items[key].blocks_per_pallet + 1) / 2
        );
        if (
          quantity / full_pallet_qty ===
          Math.floor(quantity / full_pallet_qty)
        ) {
          // Exactly even number of pallets
          items[key].pallet_quantity = Math.floor(quantity / full_pallet_qty);
          items[key].piece_quantity = 0;
        } else if (quantity <= half_pallet_qty) {
          // between 1 and half_pallet_qty, set to half pallet
          // items[key].pallet_quantity = 0
          items[key].piece_quantity = half_pallet_qty;
        } else {
          items[key].pallet_quantity = Math.floor(quantity / full_pallet_qty);
          items[key].piece_quantity = 0;
          if (
            quantity / full_pallet_qty -
              Math.floor(quantity / full_pallet_qty) <=
            0.505
          ) {
            items[key].piece_quantity = half_pallet_qty;
          } else {
            items[key].pallet_quantity = items[key].pallet_quantity + 1;
          }
        }
      }

      if (items[key].piece_quantity === 0) {
        items[key].piece_quantity = "";
      }

      this.setState({
        formItemsError: false,
        items
      });
    } else {
      if (
        Number.isInteger(quantity) &&
        items[key].blocks_per_pallet > 0 &&
        quantity >= items[key].blocks_per_pallet
      ) {
        items[key].pallet_quantity = parseInt(
          quantity / items[key].blocks_per_pallet
        );
        items[key].piece_quantity =
          quantity - items[key].blocks_per_pallet * items[key].pallet_quantity;

        this.setState({
          formItemsError: false,
          items
        });
      }
    }
  }

  submitOrder(e) {
    e.preventDefault();

    this.setState({ isSubmittingOrder: true });

    if (!this.state.location) {
      this.setState({
        formLocationError: true,
        isSubmittingOrder: false
      });
      return false;
    }

    if (
      this.state.customerPONumber.length === 0 &&
      this.state.po_required === "Y"
    ) {
      this.setState({
        formCustomerPOError: true,
        isSubmittingOrder: false
      });
      return false;
    }

    if (this.state.jobNumber.length === 0 && this.state.job_required === "Y") {
      this.setState({
        formJobNumberError: true,
        isSubmittingOrder: false
      });
      return false;
    }

    let orderQuantityGreaterThanZero = false;
    this.state.items.map(item => {
      if (item.pallet_quantity > 0 || item.piece_quantity > 0) {
        orderQuantityGreaterThanZero = true;
        return true;
      }
      return false;
    });
    if (orderQuantityGreaterThanZero === false) {
      this.setState({
        formItemsError: true,
        isSubmittingOrder: false
      });
      return false;
    }

    let totalPrice = 0;
    let items = [];
    this.state.items.map(item => {
      if ((item.pallet_quantity > 0) | (item.piece_quantity > 0)) {
        //        const listPrice = item.list_price
        const netPrice = item.net_price;

        totalPrice =
          totalPrice +
          (item.pallet_quantity * item.blocks_per_pallet +
            item.piece_quantity) *
            netPrice;

        items.push({
          item_code: item.code,
          pallet_quantity: item.pallet_quantity,
          piece_quantity: item.piece_quantity,
          price_per_item: item.price_per_item,
          blocks_per_pallet: item.blocks_per_pallet
        });
      }
      return true;
    });
    totalPrice = totalPrice.toFixed(2);
    let pickupOrDelivered = "P";
    if (this.state.pickupOrDelivered === "delivered") {
      pickupOrDelivered = "D";
    }

    let data = {
      location_code: this.state.location,
      customer_po_number: this.state.customerPONumber,
      job_number: this.state.jobNumber,
      instructions: this.state.instructions,
      pickup_or_delivered: pickupOrDelivered,
      ship_to_name: this.state.address_name,
      ship_to_addr1: this.state.address_1,
      ship_to_addr2: this.state.address_2,
      ship_to_city: this.state.address_city,
      ship_to_state: this.state.address_state,
      ship_to_zip_code: this.state.address_zip,
      total_price: totalPrice,
      items
    };

    return axios({
      method: "post",
      url: `${settings.urls.api}/customer/${this.props.profile.cust_code}/order`,
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("id_token")}`
      }
    })
      .then(result => {
        this.setState({ isSubmittingOrder: false, displayOrderThankYou: true });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isSubmittingOrder: false, displayOrderError: true });
      });
  }

  render() {
    let totalPrice = 0;
    let displayTotalPrice = "";
    let totalPaverPallets = 0;
    let totalRegPallets = 0;
    let totalSackPallets = 0;
    let totalLimePallets = 0;
    let totalCementPallets = 0;
    this.state.items.map(item => {
      //      const listPrice = item.list_price
      const netPrice = item.net_price;

      // Calculate total pallet charge

      if (
        item.code === "PPAL-998-09-00000-0000" ||
        item.code === "ZZ-PO -00-00000"
      ) {
        item.piece_quantity = totalRegPallets;
      } else {
        if (item.code === "PPAL-999-09-00000-0000") {
          item.piece_quantity = totalSackPallets;
        } else {
          if (item.code === "ZZ-LPO-00-00000") {
            item.piece_quantity = totalLimePallets;
          } else {
            if (
              item.code === "PPAL-992-09-00000-0000" ||
              item.code === "ZZ-CPO-00-00000"
            ) {
              item.piece_quantity = totalCementPallets;
            } else {
              if (item.code === "PPAL-898-09-00000-0000") {
                item.piece_quantity = totalPaverPallets;
              } else {
                if (
                  (item.code.startsWith("EZ") ||
                    item.code === "PPAV-450-09-00000-0000" ||
                    item.code === "PPAV-451-PS-00100-0000" ||
                    item.code === "YYY4-441-90-00000-0000") &&
                  item.code.substring(0, 4) !== "EZ00" &&
                  "P0,PO".indexOf(item.code.substring(9, 11)) === -1
                ) {
                  totalSackPallets =
                    totalSackPallets + item.pallet_quantity * 1;
                  if (item.piece_quantity / item.blocks_per_pallet >= 0.25) {
                    totalSackPallets = totalSackPallets + 1;
                  }
                } else {
                  if (item.code === "96-200-50-00000") {
                    // EZM lime
                    totalLimePallets =
                      totalLimePallets + item.pallet_quantity * 1;
                    if (item.piece_quantity / item.blocks_per_pallet >= 0.25) {
                      totalLimePallets = totalLimePallets + 1;
                    }
                  } else {
                    if (
                      item.code === "EZ00-101-94-00000-0000" ||
                      item.code === "EZ00-102-94-00000-0000" ||
                      item.code === "EZ00-103-2K-00000-0000" ||
                      item.code === "EZ00-103-94-00000-0000" ||
                      item.code === "EZ00-106-94-00000-0000" ||
                      item.code === "EZ00-401-94-00000-0000" ||
                      item.code === "EZ00-402-94-00000-0000" ||
                      item.code === "00-101-94-00000" ||
                      item.code === "00-102-94-00000" ||
                      item.code === "00-103-2K-00000" ||
                      item.code === "00-103-94-00000" ||
                      item.code === "00-106-94-00000" ||
                      item.code === "00-401-94-00000" ||
                      item.code === "00-402-94-00000"
                    ) {
                      // ABC and EZM Cement Pallets
                      totalCementPallets =
                        totalCementPallets + item.pallet_quantity * 1;
                      if (
                        item.piece_quantity / item.blocks_per_pallet >=
                        0.25
                      ) {
                        totalCementPallets = totalCementPallets + 1;
                      }
                    } else {
                      if (
                        "00,25,35,94,95,96,".indexOf(
                          item.code.substring(0, 2)
                        ) === -1 &&
                        item.code.substring(0, 4) !== "EZ00" &&
                        item.code.substring(0, 8) !== "PPAV-446" &&
                        "P0,PO,B0".indexOf(item.code.substring(9, 11)) === -1 &&
                        item.blocks_per_pallet > 0
                      ) {
                        if (item.product_code === "PAVER") {
                          totalPaverPallets =
                            totalPaverPallets + item.pallet_quantity * 1;
                          if (
                            item.piece_quantity / item.blocks_per_pallet >=
                            0.25
                          ) {
                            totalPaverPallets = totalPaverPallets + 1;
                          }
                        } else {
                          if (item.product_code !== "ACCESS") {
                            totalRegPallets =
                              totalRegPallets + item.pallet_quantity * 1;
                            if (
                              item.piece_quantity / item.blocks_per_pallet >=
                              0.25
                            ) {
                              totalRegPallets = totalRegPallets + 1;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      let pallet_qty = item.pallet_quantity;
      if (item.pallet_quantity === "") {
        pallet_qty = 0;
      }
      let piece_qty = item.piece_quantity;
      if (item.piece_quantity === "") {
        piece_qty = 0;
      }

      totalPrice =
        totalPrice +
        (pallet_qty * item.blocks_per_pallet + piece_qty) * netPrice;
      return true;
    });
    // add commas for thousand separators if over $1000
    totalPrice = totalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    displayTotalPrice = totalPrice;

    if (this.state.showPricing === false) {
      displayTotalPrice = "";
    }
    if (this.state.displayOrderThankYou) {
      return (
        <>
          <Nav
            isAuthenticated={this.props.isAuthenticated}
            profile={this.props.profile}
          />
          <main className="uk-container">
            <div className="uk-section uk-text-center">
              <h2>Thank you for your order!</h2>
              <p className="uk-text-center">Your order has been submitted.</p>
              <button
                type="button"
                className="uk-button uk-button-primary"
                onClick={() => {
                  this.setState({
                    address_name: "",
                    address_1: "",
                    address_2: "",
                    address_city: "",
                    address_state: "",
                    address_zip: "",
                    customerPONumber: "",
                    displayOrderThankYou: false,
                    hasSearchedForItem: false,
                    items: [],
                    jobNumber: "",
                    instructions: ""
                  });
                  this.getCustomerHistory();
                }}
              >
                Create a New Order
              </button>
            </div>
          </main>
        </>
      );
    }

    if (this.state.displayOrderError) {
      return (
        <>
          <Nav
            isAuthenticated={this.props.isAuthenticated}
            profile={this.props.profile}
          />
          <main className="uk-container">
            <div className="uk-section uk-text-center">
              <h2 className="uk-text-danger">
                Something went wrong with your order!
              </h2>
              <p className="uk-text-lead">
                Please contact customer service for your order's location.
              </p>
              <button
                type="button"
                className="uk-button uk-button-secondary"
                onClick={() =>
                  this.setState({
                    displayOrderError: false
                  })
                }
              >
                Go back
              </button>
            </div>
          </main>
        </>
      );
    }

    return (
      <>
        <Nav
          isAuthenticated={this.props.isAuthenticated}
          profile={this.props.profile}
        />
        <main className="uk-container">
          {!this.state.isSubmittingOrder ? (
            <>
              <div className="uk-section uk-section-small uk-text-center">
                <h2>New Order</h2>
              </div>

              <div className="uk-section uk-section-small">
                <div data-uk-grid="">
                  <div className="uk-width-2-3@m">
                    <div className="uk-card uk-card-default uk-card-body">
                      <h3>Order Details</h3>
                      <form>
                        <div className="uk-margin">
                          Location
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={this.getLocations}
                            onChange={this.handleUpdateLocation}
                            placeholder="Choose a location..."
                          />
                          {this.state.formLocationError && (
                            <div className="uk-text-danger">
                              You must choose a location.
                            </div>
                          )}
                        </div>

                        <div className="uk-margin">
                          Customer PO Number
                          <input
                            className="uk-input"
                            onChange={this.handleChangeCustomerPONumber}
                            type="text"
                            value={this.state.customerPONumber}
                          />
                          {this.state.formCustomerPOError && (
                            <div className="uk-text-danger">
                              You must enter a PO Number.
                            </div>
                          )}
                        </div>

                        <div className="uk-margin">
                          Job Number
                          <input
                            className="uk-input"
                            onChange={this.handleChangeJobNumber}
                            type="text"
                            value={this.state.jobNumber}
                          />
                          {this.state.formJobNumberError && (
                            <div className="uk-text-danger">
                              You must enter a Job Number.
                            </div>
                          )}
                        </div>

                        <div className="uk-margin">
                          Pickup Instructions
                          <textarea
                            className="uk-textarea"
                            rows="3"
                            onChange={this.handleChangeInstructions}
                            value={this.state.instructions}
                          />
                        </div>

                        <div className="uk-margin">
                          <div>
                            <input
                              className="uk-radio uk-margin-small-right"
                              checked={
                                this.state.pickupOrDelivered === "pickup"
                              }
                              disabled
                              name="pickupOrDelivered"
                              onChange={this.handleChangePickupOrDelivered}
                              type="radio"
                              value="pickup"
                            />
                            <label>Picked Up</label>
                          </div>
                          <div>
                            <input
                              className="uk-radio uk-margin-small-right"
                              checked={
                                this.state.pickupOrDelivered === "delivered"
                              }
                              disabled
                              name="pickupOrDelivered"
                              onChange={this.handleChangePickupOrDelivered}
                              type="radio"
                              value="delivered"
                            />
                            <label>Delivered (Coming Soon)</label>
                          </div>
                        </div>

                        {this.state.pickupOrDelivered === "delivered" && (
                          <>
                            <div className="uk-margin">
                              Name
                              <input
                                className="uk-input"
                                onChange={this.handleChangeAddressName}
                                type="text"
                                value={this.state.address_name}
                              />
                            </div>

                            <div className="uk-margin">
                              Address
                              <input
                                className="uk-input"
                                onChange={this.handleChangeAddress1}
                                placeholder="1234 Main St"
                                type="text"
                                value={this.state.address_1}
                              />
                            </div>

                            <div className="uk-margin">
                              Project Name
                              <input
                                className="uk-input"
                                type="text"
                                onChange={this.handleChangeAddress2}
                                placeholder="Project Name"
                                value={this.state.address_2}
                              />
                            </div>

                            <div className="uk-margin">
                              State
                              <select
                                className="uk-select"
                                onChange={this.handleChangeAddressState}
                              >
                                <option value="">Choose...</option>
                                <option value="AL">AL</option>
                                <option value="AK">AK</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="CA" selected>
                                  CA
                                </option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DC">DC</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MO">MO</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="NC">NC</option>
                                <option value="NE">NE</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="ND">ND</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VT">VT</option>
                                <option value="VA">VA</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                              </select>
                            </div>

                            <div className="uk-margin">
                              Zip Code
                              <input
                                type="text"
                                className="uk-input"
                                placeholder="Zip Code"
                                onChange={this.handleChangeAddressZip}
                                value={this.state.address_zip}
                              />
                            </div>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                  <div className="uk-width-1-3@m">
                    <div className="uk-card uk-card-default uk-card-body">
                      <h4>Order Summary</h4>
                      <div className="uk-section uk-section-xsmall">
                        Total (USD): <strong>{displayTotalPrice}</strong>
                      </div>
                      {/*
                      <div>
                        <button
                          className="uk-button uk-button-primary uk-width-1-1"
                          onClick={this.submitOrder}
                          type="button"
                        >
                          Submit Order
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="uk-section uk-section-small">
                <div className="uk-section uk-section-muted">
                  <div className="uk-container uk-container-xsmall">
                    <h3>Item Search</h3>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={this.loadOptions}
                      onChange={this.onUpdate}
                      placeholder="Search for an item..."
                    />
                  </div>
                </div>

                {this.state.loadingItemHistory ? (
                  <Loader />
                ) : (
                  <div className="uk-section">
                    {this.state.formItemsError && (
                      <div className="uk-text-danger">
                        You must choose at least one item.
                      </div>
                    )}
                    <table className="uk-table uk-table-small uk-table-striped">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th className="text-center">Pallet Qty</th>
                          <th className="text-center">Units per Pallet</th>
                          <th className="text-center">Piece Qty</th>
                          <th className="text-center">Total Units</th>
                          <th className="text-center">List Price</th>
                          <th className="text-center">Net Price</th>
                          <th className="text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.items.map((item, i) => {
                          const listPrice = item.list_price;
                          // let netPrice = listPrice
                          let netPrice = item.net_price;

                          //                          if (item.company_code === 'ABC') {
                          //                              if (item.is_paver) {
                          //                                netPrice = item.paver_price
                          //                              } else {
                          //                                if (this.state.pickupOrDelivered === 'delivered') {
                          //                                  netPrice = (listPrice + item.delivery_markup)
                          //                                }
                          //                                if (item.is_discountable === 'Y') {
                          //                                    netPrice = Math.round(netPrice * 10000 * (1 - (this.state.discount_rate / 100))) / 10000
                          //                                }
                          //                              }
                          //                          }
                          if (netPrice === null) {
                            netPrice = 0;
                          }

                          // render initial order page with isFromHistory
                          let displayListPrice = "$" + listPrice.toFixed(2);
                          let displayNetPrice = "$" + netPrice.toFixed(3);

                          let displayTotalPrice = "-";
                          if (item.pallet_quantity + item.piece_quantity > 0) {
                            totalPrice =
                              (item.pallet_quantity * item.blocks_per_pallet +
                                item.piece_quantity) *
                              netPrice;
                            displayTotalPrice =
                              "$" +
                              totalPrice
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          }

                          if (this.state.showPricing === false) {
                            displayListPrice = "";
                            displayNetPrice = "";
                            displayTotalPrice = "";
                          }

                          if (
                            this.state.hasSearchedForItem &&
                            item.pallet_quantity === 0 &&
                            item.piece_quantity === 0 &&
                            item.isFromHistory
                          ) {
                            return null;
                          }

                          return (
                            <tr key={item.code}>
                              <td>{item.description}</td>
                              <td>
                                <input
                                  className="uk-input uk-form-small uk-text-center"
                                  type="text"
                                  value={item.pallet_quantity}
                                  onChange={e => {
                                    this.handleChangePalletQuantity(e, i);
                                  }}
                                  onBlur={e => {
                                    this.handleExitPalletQuantity(e, i);
                                  }}
                                  style={{ maxWidth: 100 }}
                                />
                              </td>
                              <td className="uk-text-center">
                                {item.blocks_per_pallet}
                              </td>
                              <td>
                                <input
                                  className="uk-input uk-form-small uk-text-center"
                                  type="text"
                                  value={item.piece_quantity}
                                  onChange={e => {
                                    this.handleChangePieceQuantity(e, i);
                                  }}
                                  onBlur={e => {
                                    this.handleExitPieceQuantity(e, i);
                                  }}
                                  style={{ maxWidth: 100 }}
                                />
                              </td>
                              <td className="uk-text-center">
                                {item.pallet_quantity + item.piece_quantity ===
                                0 ? (
                                  <>-</>
                                ) : (
                                  <>
                                    {item.pallet_quantity *
                                      item.blocks_per_pallet +
                                      item.piece_quantity}
                                  </>
                                )}
                              </td>
                              <td className="uk-text-center">
                                {displayListPrice}
                              </td>
                              <td className="uk-text-center">
                                {displayNetPrice}
                              </td>
                              <td className="uk-text-right">
                                {displayTotalPrice}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                <button
                  className="uk-button uk-button-primary uk-button-large uk-width-1-1"
                  onClick={this.submitOrder}
                  type="submit"
                >
                  Submit Order
                </button>
              </div>
            </>
          ) : (
            <div className="uk-section uk-text-center">
              <Loader loadingText="Submitting order..." loadingTextLarge />
            </div>
          )}
        </main>
      </>
    );
  }
}

export default withRouter(Customers);
