import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Table } from 'react-bootstrap'

class CustomerDetailTable extends Component {
  render() {
    if (this.props.customer === null) {
      return <div />;
    }

    return (
      <>
        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-xsmall">
            <h4>Customer Details</h4>
            <table className="uk-table uk-table-small uk-table-divider">
              <tbody>
                <tr>
                  <td>Code</td>
                  <td>
                    <code>{this.props.customer.code}</code>
                  </td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>
                    <code>{this.props.customer.name}</code>
                  </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>
                    <code>{this.props.customer.address.addr1}</code>
                  </td>
                </tr>
                <tr>
                  <td>Project Name</td>
                  <td>
                    <code>{this.props.customer.address.addr2}</code>
                  </td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>
                    <code>{this.props.customer.address.city}</code>
                  </td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>
                    <code>{this.props.customer.address.state}</code>
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>
                    <code>{this.props.customer.phone}</code>
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <code>{this.props.customer.email}</code>
                  </td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>
                    <code>{this.props.customer.discount_rate}%</code>
                  </td>
                </tr>
                <tr>
                  <td>Paver Discount</td>
                  <td>
                    <code>{this.props.customer.paver_discount_rate}%</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-xsmall">
            <h4>Sales Rep</h4>
            <table className="uk-table uk-table-small uk-table-divider">
              <tbody>
                <tr>
                  <td>Code</td>
                  <td>
                    <code>{this.props.customer.sales_rep.code}</code>
                  </td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>
                    <code>{this.props.customer.sales_rep.name}</code>
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <code>{this.props.customer.sales_rep.email}</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="uk-section uk-section-small">
          {this.props.customer.orders.length > 0 ? (
            <div className="uk-container">
              <h4>ABC POs</h4>
              <table className="uk-table uk-table-striped uk-table-hover">
                <thead>
                  <tr>
                    <th>ABC PO #</th>
                    <th>Status</th>
                    <th>Customer Code</th>
                    <th>Ship To Name</th>
                    <th>ABC PO Date</th>
                    <th>Start Date</th>
                    <th>Price List Date</th>
                    <th>Discount %</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.customer.orders.map(order => (
                    <tr key={order.abc_po_number}>
                      <td>
                        <Link to={`/orders/${order.abc_po_number}`}>
                          {order.abc_po_number}
                        </Link>
                      </td>
                      <td>{order.abc_po_status}</td>
                      <td>{order.cust_code}</td>
                      <td>{order.cust_ship_to_name}</td>
                      <td>{order.abc_po_date}</td>
                      <td>{order.start_date}</td>
                      <td>{order.price_list_date}</td>
                      <td>{order.discount_perc}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="uk-container uk-container-xsmall">
              <h4>ABC POs</h4>
              <p>No ABC POs found.</p>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default CustomerDetailTable;
