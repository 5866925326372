import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ output }) => (
  <Link className="uk-navbar-item uk-logo" to="/">
    <img src="/logo.png" style={{ height: 35 }} alt="Logo" />
  </Link>
);

export default Logo;
