import auth0 from "auth0-js";
import settings from "../settings";
import jwt_decode from "jwt-decode";

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: settings.auth0.domain,
    clientID: settings.auth0.clientId,
    redirectUri: settings.auth0.callbackUrl,
    audience: `https://${settings.auth0.domain}/userinfo`,
    responseType: "token id_token",
    scope: "openid profile"
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login() {
    this.auth0.authorize();
    return null;
  }

  handleAuthentication(history, callback) {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        history.replace("/");
        callback();
      } else if (err) {
        history.push("/");
        callback();
        console.error(err);
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    //let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
    //let expiresAt = JSON.stringify((authResult.expiresIn * 10) + new Date().getTime())

    const decoded = jwt_decode(authResult.idToken);
    const expiresAt = decoded.exp * 1000;

    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("nickname", decoded.nickname);
    localStorage.setItem("expires_at", expiresAt);
  }

  logout(history) {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");

    // navigate to the auth0 logout url to fully log user out
    window.location.href = `https://angelusblock.auth0.com/v2/logout?client_id=${
      settings.auth0.clientId
    }&returnTo=${encodeURIComponent(settings.urls.www)}`;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }
}
