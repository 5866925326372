import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
// import createHistory from 'history/createBrowserHistory'
import { createBrowserHistory as createHistory } from "history";

// helpers
import Auth from "./helpers/Auth";
import PrivateRoute from "./helpers/PrivateRoute";

// pages
import Callback from "./pages/Callback";
import CustomerOrder from "./pages/CustomerOrder";
import Customers from "./pages/Customers";
import CustomersDetail from "./pages/CustomersDetail";
import Home from "./pages/Home";
import Items from "./pages/Items";
import ItemsDetail from "./pages/ItemsDetail";
import Orders from "./pages/Orders";
import OrdersDetail from "./pages/OrdersDetail";

const auth = new Auth();
const history = createHistory();

const Routes = props => {
  const isAuthenticated = props.isAuthenticated;
  const onAuthChange = props.onAuthChange;
  const profile = props.profile;

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/callback"
          render={props => (
            <Callback {...props} auth={auth} onAuthChange={onAuthChange} />
          )}
        />
        <Route
          exact
          path="/login"
          render={() => {
            return auth.login();
          }}
        />

        {isAuthenticated && profile.cust_code && (
          <Switch>
            <PrivateRoute
              exact
              path="/orders"
              component={CustomerOrder}
              isAuthenticated={isAuthenticated}
              profile={profile}
            />

            {/* redirect must be last in Switch group */}
            <Redirect from="/" to="/orders" />
          </Switch>
        )}

        {isAuthenticated && profile.srep_code && (
          <Switch>
            <PrivateRoute
              exact
              path="/customers"
              component={Customers}
              isAuthenticated={isAuthenticated}
              profile={profile}
            />
            <PrivateRoute
              exact
              path="/customers/:id"
              component={CustomersDetail}
              isAuthenticated={isAuthenticated}
              profile={profile}
            />
            <PrivateRoute
              exact
              path="/items"
              component={Items}
              isAuthenticated={isAuthenticated}
              profile={profile}
            />
            <PrivateRoute
              exact
              path="/items/:id"
              component={ItemsDetail}
              isAuthenticated={isAuthenticated}
              profile={profile}
            />
            <PrivateRoute
              exact
              path="/orders"
              component={Orders}
              isAuthenticated={isAuthenticated}
              profile={profile}
            />
            <PrivateRoute
              exact
              path="/orders/:id"
              component={OrdersDetail}
              isAuthenticated={isAuthenticated}
              profile={profile}
            />

            {/* redirect must be last in Switch group */}
            <Redirect from="/" to="/items" />
          </Switch>
        )}

        {!isAuthenticated && <Route exact path="/" component={Home} />}

        {isAuthenticated && profile.uuid && <Redirect to="/" />}

        {/* Finally, if no other routes match, redirect to the root */}
        {!isAuthenticated && <Redirect to="/" />}
      </Switch>
    </Router>
  );
};

export default Routes;
