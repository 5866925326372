import React from "react";
import { Link } from "react-router-dom";

// Components
import Nav from "../components/Navi";

class Home extends React.Component {
  render() {
    return (
      <>
        <Nav
          isAuthenticated={this.props.isAuthenticated}
          profile={this.props.profile}
        />
        <div className="uk-container uk-text-center">
          <div className="uk-section">
            <img src="/logo-full.png" alt="Logo" />
          </div>
          <div className="uk-section">
            <h1>Welcome to the EZ Mix Dealer Order Portal</h1>
            <p className="uk-text-lead">Click the link below to login.</p>
            <Link
              className="uk-button uk-button-primary"
              to="/login"
              type="button"
            >
              Login
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
