import React from "react";
import axios from "axios";
import AsyncSelect from "react-select/lib/Async";
import settings from "../settings";

// Components
import CustomerDetailTable from "../components/CustomerDetailTable";
import Loader from "../components/Loader";
import Nav from "../components/Navi";

class CustomersDetail extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      customer: null,
      loading: true
    };

    this.getCustomerDetails = this.getCustomerDetails.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.setState({ customer: null, loading: true }, () => {
        this.getCustomerDetails(nextProps.match.params.id);
      });
    }
  }

  componentDidMount() {
    this.getCustomerDetails(this.props.match.params.id);
  }

  getCustomerDetails(customerId) {
    this.setState({ loading: true });
    axios
      .get(`${settings.urls.api}/customer/${customerId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        this.setState({
          customer: result.data
        });
      })
      .catch(e => {
        console.error("error: ", e);
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  loadOptions = inputValue => {
    return new Promise(resolve => {
      this.findItem(inputValue).then(result => {
        resolve(result);
      });
    });
  };

  findItem = inputValue => {
    return axios
      .get(
        `${settings.urls.api}/customer/search?name=${inputValue.toUpperCase()}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("id_token")}`
          }
        }
      )
      .then(result => {
        const items = [];
        result.data
          .sort((a, b) => (a.code > b.code ? 1 : -1))
          .map(customer => {
            return items.push({
              value: customer.code,
              label: `${customer.name} (${customer.code})`
            });
          });
        return items;
      });
  };

  onUpdate = selectedItem => {
    this.props.history.push(`/customers/${selectedItem.value}`);
  };

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  render() {
    return (
      <>
        <Nav
          isAuthenticated={this.props.isAuthenticated}
          profile={this.props.profile}
        />
        <main className="uk-container">
          <div className="uk-section uk-section-muted">
            <div className="uk-container uk-container-xsmall">
              <h3>Customer Search</h3>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={this.loadOptions}
                onChange={this.onUpdate}
                placeholder="Search for a customer..."
              />
            </div>
          </div>
          {this.state.loading ? (
            <Loader />
          ) : (
            <div className="uk-section uk-section-small">
              <div className="uk-container">
                <CustomerDetailTable customer={this.state.customer} />
              </div>
            </div>
          )}
        </main>
      </>
    );
  }
}

export default CustomersDetail;
