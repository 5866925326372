import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AsyncSelect from "react-select/lib/Async";
import settings from "../settings";

// Components
import Nav from "../components/Navi";

class Orders extends React.Component {
  loadOptions = inputValue => {
    return new Promise(resolve => {
      this.findItem(inputValue).then(result => {
        resolve(result);
      });
    });
  };

  findItem = inputValue => {
    return axios
      .get(`${settings.urls.api}/order/search?query=${inputValue}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        const items = [];
        result.data.map(item => {
          return items.push({
            value: item.abc_po_number,
            label: `${item.abc_po_number} (${item.cust_code}) ${item.cust_ship_to_name}`
          });
        });
        return items;
      });
  };

  onUpdate = selectedItem => {
    this.props.history.push(`/orders/${selectedItem.value}`);
  };

  render() {
    return (
      <>
        <Nav
          isAuthenticated={this.props.isAuthenticated}
          profile={this.props.profile}
        />
        <main className="uk-container">
          <div className="uk-section uk-section-muted">
            <div className="uk-container uk-container-xsmall">
              <h3>ABC PO Search</h3>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={this.loadOptions}
                onChange={this.onUpdate}
                placeholder="Search for an ABC PO..."
              />
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withRouter(Orders);
