import React from "react";
import axios from "axios";
import AsyncSelect from "react-select/lib/Async";

import settings from "../settings";

// Components
import Nav from "../components/Navi";

class Items extends React.Component {
  loadOptions = inputValue => {
    return new Promise(resolve => {
      this.findItem(inputValue).then(result => {
        resolve(result);
      });
    });
  };

  findItem = inputValue => {
    return axios
      .get(`${settings.urls.api}/item/search?query=${inputValue}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        const items = [];
        result.data.map(item => {
          let list_price = item.list_price;
          if (item.is_paver) {
            list_price = item.paver_list_price;
          }
          const delivered_price =
            parseFloat(item.delivery_markup) + parseFloat(list_price);
          return items.push({
            value: item.code,
            label: `${item.description} (${
              item.total_qty
            }) $${delivered_price.toFixed(2)}`
          });
        });
        return items;
      });
  };

  onUpdate = selectedItem => {
    this.props.history.push(`/items/${selectedItem.value}`);
  };

  render() {
    return (
      <>
        <Nav
          isAuthenticated={this.props.isAuthenticated}
          profile={this.props.profile}
        />
        <main className="uk-container">
          <div className="uk-section uk-section-muted">
            <div className="uk-container uk-container-xsmall">
              <h3>Item Search</h3>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={this.loadOptions}
                onChange={this.onUpdate}
                placeholder="Search for an item..."
              />
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Items;
