import React from "react";
import axios from "axios";
import AsyncSelect from "react-select/lib/Async";
import settings from "../settings";

// Components
import Nav from "../components/Navi";
import Loader from "../components/Loader";
import OrderDetailTable from "../components/OrderDetailTable";

class OrdersDetail extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: null,
      items: [],
      loading: true
    };

    this.getOrderDetails = this.getOrderDetails.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.setState({ order: null, items: [], loading: true }, () => {
        this.getOrderDetails(nextProps.match.params.id);
      });
    }
  }

  componentDidMount() {
    this.getOrderDetails(this.props.match.params.id);
  }

  getOrderDetails(orderId) {
    this.setState({ loading: true });
    axios
      .get(`${settings.urls.api}/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        const items = result.data.items;
        delete result.data.items;
        this.setState({
          order: result.data,
          items
        });
      })
      .catch(e => {
        console.error("error: ", e);
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  loadOptions = inputValue => {
    return new Promise(resolve => {
      this.findItem(inputValue).then(result => {
        resolve(result);
      });
    });
  };

  findItem = inputValue => {
    return axios
      .get(`${settings.urls.api}/order/search?query=${inputValue}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        const orders = [];
        result.data.map(order => {
          return orders.push({
            value: order.abc_po_number,
            label: `${order.abc_po_number} (${order.cust_code}) ${order.cust_ship_to_name}`
          });
        });
        return orders;
      });
  };

  onUpdate = selectedItem => {
    this.props.history.push(`/orders/${selectedItem.value}`);
  };

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  render() {
    return (
      <>
        <Nav
          isAuthenticated={this.props.isAuthenticated}
          profile={this.props.profile}
        />
        <main className="uk-container">
          <div className="uk-section uk-section-muted">
            <div className="uk-container uk-container-xsmall">
              <h3>ABC PO Search</h3>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={this.loadOptions}
                onChange={this.onUpdate}
                placeholder="Search for an ABC PO..."
              />
            </div>
          </div>
          {this.state.loading ? (
            <Loader />
          ) : (
            <div className="uk-section uk-section-small">
              <div className="uk-container">
                <OrderDetailTable
                  order={this.state.order}
                  items={this.state.items}
                />
              </div>
            </div>
          )}
        </main>
      </>
    );
  }
}

export default OrdersDetail;
