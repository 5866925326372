import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import ReactTable from 'react-table'
// import 'react-table/react-table.css'
import axios from "axios";
import settings from "../settings";

class OrderDetailTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGeneratingPDF: false
    };

    this.handleABCDisplay = this.handleABCDisplay.bind(this);
  }

  handleABCDisplay(event, abcpo, cust_code) {
    event.preventDefault();

    this.setState({ isGeneratingPDF: true });

    return axios
      .get(
        `${settings.urls.api}/abcpo/display/${abcpo}&CUST-CODE=${cust_code}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("id_token")}`
          }
        }
      )
      .then(result => {
        this.props.history.push(result.data);
        window.location.replace(result.data);
        this.setState({ isGeneratingPDF: false });
        return null;
      });
  }

  render() {
    if (this.props.order === null) {
      return <div />;
    }

    return (
      <>
        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-xsmall">
            <h4>ABC PO Details</h4>
            <table className="uk-table uk-table-small uk-table-divider">
              <tbody>
                <tr>
                  <td>View ABC PO</td>
                  <td>
                    {!this.state.isGeneratingPDF ? (
                      <button
                        className="uk-button uk-button-small uk-button-primary"
                        onClick={event => {
                          this.handleABCDisplay(
                            event,
                            this.props.order.abc_po_number,
                            this.props.order.cust_code
                          );
                        }}
                      >
                        ABC PO Display
                      </button>
                    ) : (
                      <div data-uk-spinner />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>ABC PO Number</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.abc_po_number}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Customer Code</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.cust_code}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Sales Rep</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.srep_code}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Ship to Name</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.cust_ship_to_name}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Ship to Address</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.cust_ship_to_addr1}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Project Name</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.cust_ship_to_addr2}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Ship to City</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.cust_ship_to_city}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Ship to State</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.cust_ship_to_state}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>ABC PO Date</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.abc_po_date}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Start Date</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.start_date}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Price List Date</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.price_list_date}
                    </code>
                  </td>
                </tr>
                <tr>
                  <td>Discount %</td>
                  <td>
                    <code className="highlighter-rouge">
                      {this.props.order.discount_perc}
                    </code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="uk-section uk-section-small">
          {this.props.items.length > 0 ? (
            <div className="uk-container">
              <h4>Items</h4>

              <table className="uk-table uk-table-striped uk-table-hover">
                <thead>
                  <tr>
                    <th>Line</th>
                    <th>Item Description</th>
                    <th>Disc %</th>
                    <th>ABC PO Qty</th>
                    <th>Shipped</th>
                    <th>Remaining</th>
                    <th>Location</th>
                    <th>Qty on Hand</th>
                    <th>Qty Commit</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.items.map(item => (
                    <tr key={item.abc_po_line_num}>
                      <td>{item.abc_po_line_num}</td>
                      <td>
                        <Link to={`/items/${item.item_code}`}>
                          {item.item_desc}
                        </Link>
                      </td>
                      <td>{item.line_discount_perc}</td>
                      <td>{item.abc_po_qty}</td>
                      <td>{item.qty_shipped}</td>
                      <td>{item.qty_remaining}</td>
                      <td>{item.line_whse_code}</td>
                      <td>{item.whse_qty_on_hand}</td>
                      <td>{item.abc_qty_commit}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="uk-container uk-container-xsmall">
              <h4>ABC POs</h4>
              <p>No items found.</p>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(OrderDetailTable);
