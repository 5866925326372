const _ = require("lodash");

// load configuration from files
const env = process.env.REACT_APP_STAGE || "local";
const config = require(`./config/${env}`);

// Contants used throughout application
const { themes } = require("./config/constants");

// General settings that apply to all environments (but can be overridden).
const generalSettings = {
  env,
  themes
};

const settings = _.merge(generalSettings, _.omitBy(config, _.isFunction));

// export the configuration object
module.exports = settings;
