import axios from "axios";
import React from "react";

// Core
import Routes from "./routes";
import settings from "./settings";

// Helpers
import Auth from "./helpers/Auth";

class App extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isAuthenticated: null,
      profile: {
        uuid: null,
        email: null,
        name: null,
        cust_code: null,
        srep_code: null,
        show_all_customers: null
      },
      loadingProfile: false
    };

    this.auth = new Auth();
    this.getProfile = this.getProfile.bind(this);
    this.handleAuthChange = this.handleAuthChange.bind(this);
  }

  componentDidMount() {
    // Segment Script
    const script = document.createElement("script");
    const scriptText = document.createTextNode(
      `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0"; analytics.load('${settings.segment.key}');}}();`
    );
    script.appendChild(scriptText);
    document.body.appendChild(script);

    const isAuthenticated = this.auth.isAuthenticated();
    if (isAuthenticated) {
      this.getProfile();
    }

    this.setState({ isAuthenticated: this.auth.isAuthenticated() });
  }

  getProfile() {
    this.setState({ loadingProfile: true });
    axios
      .get(`${settings.urls.api}/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("id_token")}`
        }
      })
      .then(result => {
        this.setState({ profile: result.data });
      })
      .catch(e => {
        console.error("error: ", e);
      })
      .finally(() => {
        this.setState({ loadingProfile: false });
      });
  }

  handleAuthChange() {
    const isAuthenticated = this.auth.isAuthenticated();
    if (isAuthenticated) {
      this.getProfile();
    }

    this.setState({ isAuthenticated });
  }

  render() {
    if (this.state.isAuthenticated !== null) {
      return (
        <Routes
          isAuthenticated={this.state.isAuthenticated}
          profile={this.state.profile}
          onAuthChange={this.handleAuthChange}
        />
      );
    }
    return <div />;
  }
}

export default App;
