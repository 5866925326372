import React, { Component } from "react";

class ItemDetailTable extends Component {
  render() {
    const item = this.props.item;

    if (item === null) {
      return <div />;
    }

    const delivered_price =
      parseFloat(item.delivery_markup) + parseFloat(item.list_price);

    return (
      <>
        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-xsmall">
            <h4>Item Details</h4>
            <table className="uk-table uk-table-divider">
              <tbody>
                <tr>
                  <td>Item Code</td>
                  <td>
                    <code>{item.item_code}</code>
                  </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>
                    <code>{item.item_desc}</code>
                  </td>
                </tr>
                <tr>
                  <td>Product Code</td>
                  <td>
                    <code>{item.product_code}</code>
                  </td>
                </tr>
                {!item.is_paver ? (
                  <>
                    <tr>
                      <td>Picked Up Price</td>
                      <td>
                        <code>${parseFloat(item.list_price).toFixed(2)}</code>
                      </td>
                    </tr>
                    <tr>
                      <td>Delivered Price</td>
                      <td>
                        <code>${delivered_price.toFixed(2)}</code>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>Retail Price</td>
                      <td>
                        <code>
                          ${parseFloat(item.paver_list_price).toFixed(2)}
                        </code>
                      </td>
                    </tr>
                    <tr>
                      <td>Contractor Price</td>
                      <td>
                        <code>
                          ${parseFloat(item.paver_contractor_price).toFixed(2)}
                        </code>
                      </td>
                    </tr>
                    <tr>
                      <td>Dealer Price</td>
                      <td>
                        <code>
                          ${parseFloat(item.paver_dealer_price).toFixed(2)}
                        </code>
                      </td>
                    </tr>
                    <tr>
                      <td>Delivery Charge</td>
                      <td>
                        <code>
                          ${parseFloat(item.delivery_markup).toFixed(2)}
                        </code>
                      </td>
                    </tr>
                  </>
                )}

                {item.item_pic_name && (
                  <tr>
                    <td>Config</td>
                    <td>
                      <img
                        src={"/images/" + item.item_pic_name}
                        width="130"
                        alt=""
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-xsmall">
            <h4>Items on Hand</h4>
            {this.props.warehouseItems.length > 0 ? (
              <table className="uk-table uk-table-striped uk-table-hover">
                <thead>
                  <tr>
                    <th>Location</th>
                    <th>Quantity on Hand</th>
                    <th>On ABC PO</th>
                    <th>Available</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.warehouseItems.map(item => (
                    <tr key={item.whse_code}>
                      <td>{item.whse_code}</td>
                      <td>{item.quantity}</td>
                      <td>{item.on_abc_po}</td>
                      <td>{item.available}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>Nothing on hand for this item</p>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ItemDetailTable;
